<template>
  <div class="range-slider">
    <div ref="slider" class="sliders">
      <input
        id="min"
        name="min"
        :value="minValue"
        type="range"
        :min="min"
        :max="max"
        :step="step"
        @input="({ target }) => (sliderMinValue = parseFloat(target.value))"
      >
      <input
        id="max"
        name="max"
        :value="maxValue"
        type="range"
        :min="min"
        :max="max"
        :step="step"
        @input="({ target }) => (sliderMaxValue = parseFloat(target.value))"
      >
    </div>
    <div class="flex items-center justify-between">
      <div class="text-lg">
        {{ $t('priceSlider.from') }}
        <span class="font-bold">{{ formatPrice.format(sliderMinValue) }}</span>
      </div>
      <div class="text-lg">
        {{ $t('priceSlider.to') }}
        <span class="font-bold">{{ formatPrice.format(sliderMaxValue) }}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
const { locale } = useI18n()
const props = defineProps({
  min: {
    type: Number,
    default: 50
  },
  max: {
    type: Number,
    default: 100
  },
  step: {
    type: Number,
    default: 1
  },
  minValue: {
    type: Number,
    default: 50
  },
  maxValue: {
    type: Number,
    default: 80
  }
})
// Define emits for the slider component
const emit = defineEmits(['update:minValue', 'update:maxValue'])
// Refs for slider element and the slider values
const slider = ref(null)
const sliderMinValue = ref(props.minValue)
const sliderMaxValue = ref(props.maxValue)
// Get the percentage of a value between the min and max values
const getPercent = (value, min, max) => {
  return ((value - min) / (max - min)) * 100
}
// Difference between the min and max values
const sliderDifference = computed(() => {
  return Math.abs(sliderMaxValue.value - sliderMinValue.value)
})
// Set the css variables for width, left, and right
const setCSSProps = (width, left, right) => {
  slider.value.style.setProperty('--width', `${width}%`)
  slider.value.style.setProperty('--progressLeft', `${left}%`)
  slider.value.style.setProperty('--progressRight', `${right}%`)
}
// When the slider values change
watchEffect(() => {
  if (slider.value) {
    // Emit slider values when updated
    emit('update:minValue', sliderMinValue.value)
    emit('update:maxValue', sliderMaxValue.value)
    // Calculate values in percentages
    const differencePercent = getPercent(sliderDifference.value, props.min, props.max)
    const leftPercent = getPercent(sliderMinValue.value, props.min, props.max)
    const rightPercent = getPercent(sliderMaxValue.value, props.min, props.max)
    // Set the CSS variables
    setCSSProps(differencePercent, leftPercent, rightPercent)
  }
})
// Format price
let localeIso = 'en-EN'
switch (locale.value) {
  case 'nl':
    localeIso = 'nl-NL'
    break
  case 'de':
    localeIso = 'de-DE'
    break
  case 'fr':
    localeIso = 'fr-FR'
    break
}
// Use like formatPrice.format(1000.95)
const formatPrice = new Intl.NumberFormat(localeIso, {
  style: 'currency',
  currency: 'EUR',
  maximumFractionDigits: 0 // No decimals
})
// Fix priceslider min > max
watch(sliderMinValue, () => {
  if (sliderMinValue.value > sliderMaxValue.value) {
    sliderMaxValue.value = sliderMinValue.value
  }
})
watch(sliderMaxValue, () => {
  if (sliderMinValue.value > sliderMaxValue.value) {
    sliderMinValue.value = sliderMaxValue.value
  }
})
// Reset priceslider when min/max value changes
watch(() => props.min, () => {
  if (!onPageload.value) {
    sliderMinValue.value = props.min
  }
})
watch(() => props.max, () => {
  if (!onPageload.value) {
    sliderMaxValue.value = props.max
  }
})
const onPageload = ref(true)
onMounted(() => {
  setTimeout(() => {
    onPageload.value = false
  }, 1000)
})
</script>

<style lang="postcss" scoped>
.range-slider {
  @apply flex flex-col w-full mt-3.5;
  .sliders{
    @apply relative min-h-[25px];
    input[type="range"] {
      @apply appearance-none h-[5px] w-full absolute pointer-events-none rounded;
      background: linear-gradient(90deg, rgb(197, 197, 197) var(--progressLeft), rgb(60,195,175) var(--progressLeft), rgb(60,195,175) var(--progressRight), rgb(197, 197, 197) var(--progressRight));
      &::-webkit-slider-thumb {
        @apply appearance-none w-[18px] h-[18px] bg-white rounded-full border-white cursor-pointer shadow-[0_0_0_4px_rgb(60,195,175)];
        pointer-events: all;
      }
      &::-moz-range-thumb {
        @apply appearance-none w-[18px] h-[18px] bg-white rounded-full border-white cursor-pointer shadow-[0_0_0_4px_rgb(60,195,175)];
        pointer-events: all;
      }
      &::-webkit-slider-thumb:hover {
        @apply bg-green transition-colors;
      }
      &#min {
        @apply mt-[2.5px] h-0 z-[1];
      }
    }
  }
}
</style>
