<template>
  <div class="slider">
    <input
      ref="slider"
      :value="sliderValue"
      type="range"
      :min="min"
      :max="max"
      :step="step"
      class="slider"
      @input="({ target }) => (sliderValue = parseFloat(target.value))"
    >
    <div class="flex items-center justify-between pt-3 mb-2">
      <div v-if="sliderValue > 0" class="text-lg">
        {{ $t('priceSlider.max') }}
        <span class="font-bold">{{ sliderValue }}Km</span>
      </div>
      <div v-else class="text-lg">
        {{ $t('priceSlider.all') }}
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  min: {
    type: Number,
    default: 0
  },
  max: {
    type: Number,
    default: 100
  },
  step: {
    type: Number,
    default: 1
  },
  modelValue: {
    type: Number,
    default: 50
  }
})
// define emits for the slider component
const emit = defineEmits(['update:modelValue'])
// define refs for the slider component
const sliderValue = ref(props.modelValue)
const slider = ref(null)
// Get the percentage of a value between the min and max values
const getProgress = (value, min, max) => {
  return ((value - min) / (max - min)) * 100
}
// Set css variable for the progress
const setCSSProgress = (progress) => {
  slider.value.style.setProperty('--ProgressPercent', `${progress}%`)
}
// watchEffect to update the css variable when the slider value changes
watchEffect(() => {
  if (slider.value) {
    // Emit the updated value to the parent component
    emit('update:modelValue', sliderValue.value)
    // Update the slider progress
    const progress = getProgress(
      sliderValue.value,
      slider.value.min,
      slider.value.max
    )
    // Set the css variable
    setCSSProgress(progress)
  }
})
</script>
<style lang="postcss" scoped>
.slider{
  @apply relative mt-1;
  input[type="range"] {
    @apply appearance-none h-[5px] w-full absolute pointer-events-none rounded;
    background: linear-gradient(90deg, rgb(197, 197, 197) var(--ProgressPercent), rgb(60,195,175) var(--ProgressPercent));
    &::-webkit-slider-thumb {
      @apply appearance-none w-[18px] h-[18px] bg-white border-white rounded-full cursor-pointer shadow-[0_0_0_4px_rgb(60,195,175)];
      pointer-events: all;
    }
    &::-moz-range-thumb {
      @apply appearance-none w-[18px] h-[18px] bg-white border-white rounded-full cursor-pointer shadow-[0_0_0_4px_rgb(60,195,175)];
      pointer-events: all;
    }
    &::-webkit-slider-thumb:hover {
      @apply bg-green transition-colors;
    }
  }
}
</style>
